import React, { useEffect, useState } from "react";
import logo from "../../../images/logo.svg";
import Language from "../Language";
import NavItem from "../NavItem";
import Switcher from "../../../Components/Switcher/Switcher";
import { Link, NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function DesktopVersion({ darkSide, toggleDarkMode, setLang }) {
  const [navbar, setNavbar] = useState(false);
  const [navScrollStyles, setNavScrollStyles] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 100) {
        setNavScrollStyles("shadow ");
      } else {
        setNavScrollStyles("");
      }
    };
  });
  return (
    <>
      <nav
        className={`${navScrollStyles}   dark:text-slate-400 w-full   backdrop-blur-lg font-main`}
        style={{ position: "fixed", top: 0, zIndex: 20 }}
      >
        <div className=" container  mx-auto gap-[70px]  md:items-center md:flex ">
          <div className="">
            <div className="flex items-center justify-between  pb-[20px] md:h-[73px] md:block">
              <Link
                to="/"
                onClick={() => {
                  window.scroll({
                    top: -30,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <img
                  width="78px"
                  height="36px"
                  src={logo}
                  className="w-[78px] h-[36px] lg:w-[106px] lg:h-[48px]    dark:brightness-200 relative top-3"
                  alt="Softclub"
                />
              </Link>
              <div className="md:hidden relative top-2.5 ">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setTimeout(() => setNavbar(!navbar), 10)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="">
            <div
              className={`flex-1 justify-self-center w-[950px] xs:pb-3 md:block md:pb-0 md:mt-0 ${
                navbar ? "block " : "hidden"
              }`}
            >
              <div className="flex md:justify-between justify-start  items-center md:flex-row gap-3 lg:gap-6 xs:space-y-1 md:space-y-0">
                <ul className="flex lg:flex-row flex-col  items-center gap-[8px]">
                  <NavLink
                    to="/"
                    onClick={() => {
                      window.scroll({
                        top: 3900,
                        left: 0,
                        behavior: "smooth",
                      });
                      setNavbar(!navbar);
                    }}
                    className={
                      " text-[#262626] dark:text-gray-200 hover:text-[#075985]  px-4 py-2  rounded-lg duration-300 hover:bg-sky-200 dark:hover:bg-[#075985] active:bg-sky-300 text-[16px]  font-[500]"
                    }
                  >
                    {" "}
                    <NavItem content={t("navbar.courses")} className="" />
                  </NavLink>
                  <NavLink
                    to="/Blogs"
                    onClick={() => {
                      window.scroll({
                        top: -20,
                        left: 0,
                        behavior: "smooth",
                      });
                      setNavbar(!navbar);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? " text-[16px] leading-[24px]  font-[500] text-[#075985] dark:text-gray-200  transition ease-in-out delay-150 bg-sky-300 dark:bg-[#075985] px-4 py-2 rounded-lg  active:bg-sky-400"
                        : " text-[#262626] dark:text-gray-200 hover:text-[#075985]  px-4 py-2  rounded-lg duration-300 hover:bg-sky-200 dark:hover:bg-[#075985] active:bg-sky-300 text-[16px]  font-[500]"
                    }
                  >
                    {" "}
                    <NavItem
                      content={t("navbar.news")}
                      style={({ isActive }) => ({
                        color: isActive ? "#fff" : "#545e6f",
                        background: isActive ? "#7600dc" : "#f0f0f0",
                      })}
                    />
                  </NavLink>
                  <NavLink
                    to="/AboutUs"
                    onClick={() => {
                      window.scroll({
                        top: -20,
                        left: 0,
                        behavior: "smooth",
                      });
                      setNavbar(!navbar);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? " text-[16px] leading-[24px]  font-[500] text-[#075985] dark:text-gray-200  transition ease-in-out delay-150 bg-sky-300 dark:bg-[#075985] px-4 py-2 rounded-lg  active:bg-sky-400"
                        : " text-[#262626] dark:text-gray-200 hover:text-[#075985]  px-4 py-2  rounded-lg duration-300 hover:bg-sky-200 dark:hover:bg-[#075985] active:bg-sky-300 text-[16px]  font-[500]"
                    }
                  >
                    {" "}
                    <NavItem content={t("navbar.about-us")} />
                  </NavLink>
                </ul>
                <div>
                  <div className="flex items-center gap-7 xs:flex-col md:flex-row  lg:ml-10 ">
                    <div className="xs:pl-[20px] xs:pt-[10px] md:pl-[0px] md:pt-[0px]">
                      <Switcher
                        darkSide={darkSide}
                        toggleDarkMode={toggleDarkMode}
                      />
                    </div>
                    <div className="xs:w-[100%] md:w-[100px] xs:flex xs:pl-[15px] md:p-[0px] xs:justify-center ">
                      <Language
                        onClick={() => setNavbar(!navbar)}
                        className=""
                        setLang={setLang}
                      />
                    </div>
                    <div>
                      <Button
                        href="https://crm.omuz.tj/"
                        target="_blank"
                        variant="outlined"
                        sx={{
                          borderColor: "#0284C7",
                          color: "#0284C7",
                          py: "10px",
                          px: "20px",
                          borderRadius: "10px",
                          fontSize: "16px",
                          fontWeight: "600",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Войти
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default DesktopVersion;
