import React from "react";

const Mentors = ({
  avatar,
  name,
  about,
  courseSkills, // assuming images is an array of objects with skillImage property
}) => {
  return (
    <div className="bg-white dark:bg-[#161F2D] overflow-hidden flex flex-col justify-between items-center font-main h-[430px] sm:h-[391px] sm:max-h-[391px] p-6 rounded-2xl ">
      <div className="flex items-center justify-between gap-4 mb-4">
        <img
          className="w-[80px] h-[80px] rounded-full"
          src={avatar}
          alt={name}
        />
        <div>
          <h2 className=" xs2:text-[16px] md:text-xl font-bold dark:text-[#FAFAFA] text-gray-900">
            {name}
          </h2>
        </div>
      </div>
      <div>
        <h1
          dangerouslySetInnerHTML={{ __html: about }}
          className="text-trueGray700 text-start  dark:text-trueGray200 text-[14px] font-[500] mb-[5px]"
        />
      </div>
      <div className="flex items-center  gap-2 mt-4">
        {courseSkills.map((skill, index) => (
          <img
            key={index}
            src={`${process.env.REACT_APP_ASSETS_URL}${skill?.skillImage}`}
            alt={`name${index}`}
            className="h-10 container"
          />
        ))}
      </div>
    </div>
  );
};

export default Mentors;
