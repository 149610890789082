import CloseIcon from "@mui/icons-material/Close";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Switcher from "../../../Components/Switcher/Switcher";
import logo from "../../../images/logo.svg";
import Language from "../Language";
import { useTranslation } from "react-i18next";

function LabtopVersion({ darkSide, toggleDarkMode, setLang, lang }) {
  const [navbar, setNavbar] = useState(false);
  const [navScrollStyles, setNavScrollStyles] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 100) {
        setNavScrollStyles("shadow");
      } else {
        setNavScrollStyles("");
      }
    };
  });
  return (
    <>
      <nav
        className={`${
          !navbar && navScrollStyles
        } dark:text-slate-400 w-full backdrop-blur-lg font-main`}
        style={{
          position: "fixed",
          top: 0,
          zIndex: 20,
          height: navbar ? "auto" : "72px",
        }}
      >
        <ul className="flex items-center mt-4 container m-auto  justify-between">
          <li className="flex gap-5 items-center">
            <div>
              <IconButton
                onClick={() => setTimeout(() => setNavbar(!navbar), 10)}
              >
                {!navbar ? (
                  <MenuRoundedIcon fontSize="large" color="info" />
                ) : (
                  <CloseIcon fontSize="large" color="info" />
                )}
              </IconButton>
            </div>
            <Link
              to="/"
              onClick={() => {
                window.scroll({
                  top: -30,
                  left: 0,
                  behavior: "smooth",
                });
                setNavbar(false);
              }}
            >
              <img src={logo} className="w-[105px] h-[44px]" alt="softclub" />
            </Link>
          </li>
          <li>
            <div className="flex items-center gap-5">
              <div>
                <Switcher darkSide={darkSide} toggleDarkMode={toggleDarkMode} />
              </div>
              <div className="xs:w-[100%] md:w-[80px] xs:flex xs:pl-[15px] md:p-[0px] xs:justify-center ">
                <Language
                  onClick={() => setNavbar(!navbar)}
                  className=""
                  setLang={setLang}
                />
              </div>
              <div>
                <Button
                  variant="outlined"
                  href="https://crm.omuz.tj/"
                  target="_blank"
                  sx={{
                    borderColor: "#0284C7",
                    color: "#0284C7",
                    py: "10px",
                    px: "20px",
                    borderRadius: "10px",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  Войти
                </Button>
              </div>
            </div>
          </li>
        </ul>
        <div
          className={
            navbar
              ? `top-[60px] transform  transition-transform duration-300 font-main ease-in-out`
              : `hidden `
          }
        >
          <div className="p-4 text-white text-center mt-4">
            <ul className="flex flex-col space-y-4 justify-center">
              <NavLink
                to="/"
                onClick={() => {
                  window.scroll({
                    top: 2550,
                    left: 0,
                    behavior: "smooth",
                  });
                  setNavbar(!navbar);
                }}
                className={
                  " text-[#262626] dark:text-gray-200 hover:text-[#075985]  px-4 py-2  rounded-lg duration-300 hover:bg-sky-200 dark:hover:bg-[#075985] active:bg-sky-300 text-[16px]  font-[500]"
                }
              >
                {t("navbar.courses")}
              </NavLink>
              <NavLink
                to="/Blogs"
                onClick={() => {
                  window.scroll({
                    top: -20,
                    left: 0,
                    behavior: "smooth",
                  });
                  setNavbar(!navbar);
                }}
                className={({ isActive }) =>
                  isActive
                    ? " text-[16px] leading-[24px]  font-[500] text-[#075985] dark:text-gray-200  transition ease-in-out delay-150 bg-sky-300 dark:bg-[#075985] px-4 py-2 rounded-lg  active:bg-sky-400"
                    : " text-[#262626] dark:text-gray-200 hover:text-[#075985]  px-4 py-2  rounded-lg duration-300 hover:bg-sky-200 dark:hover:bg-[#075985] active:bg-sky-300 text-[16px]  font-[500]"
                }
              >
                {t("navbar.news")}
              </NavLink>
              <NavLink
                to="/AboutUs"
                onClick={() => {
                  window.scroll({
                    top: -20,
                    left: 0,
                    behavior: "smooth",
                  });
                  setNavbar(!navbar);
                }}
                className={({ isActive }) =>
                  isActive
                    ? " text-[16px] leading-[24px]  font-[500] text-[#075985] dark:text-gray-200  transition ease-in-out delay-150 bg-sky-300 dark:bg-[#075985] px-4 py-2 rounded-lg  active:bg-sky-400"
                    : " text-[#262626] dark:text-gray-200 hover:text-[#075985]  px-4 py-2  rounded-lg duration-300 hover:bg-sky-200 dark:hover:bg-[#075985] active:bg-sky-300 text-[16px]  font-[500]"
                }
              >
                {t("navbar.about-us")}
              </NavLink>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default LabtopVersion;
