import { Button } from "antd";
import { useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { useGetRevieversQuery } from "../../api/reviewers";
import "./CustomSlider.css"; // Добавьте этот файл для кастомных стилей
import Slider from "react-slick";
import ReviewCard from "../../Containers/Reviews/reviewCard";
import LeftOutlined from "./IconLeft";
import RightOutlined from "./IconRightOutlined";

const CustomPaging = (i, currentSlide) => (
  <div className={`custom-dot ${currentSlide === i ? "active" : ""}`}>
    <div className="dot"></div>
  </div>
);

const ExperimentalSwiper = ({ header, desc, slideKey }) => {
  const [, lang] = useOutletContext();
  const { data: reviewers = [] } = useGetRevieversQuery({ lang: lang });

  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    customPaging: (i) =>
      CustomPaging(i, sliderRef.current?.innerSlider?.state.currentSlide),
    appendDots: (dots) => <ul>{dots}</ul>,
    slidesToScroll: 1,
  };

  if (slideKey === "studentReviews") {
    return (
      <div className="container mx-auto mt-10   sm:mt-2">
        <div className="flex justify-between px-5  items-center mb-10 ">
          <div>
            <p
              style={{ textAlign: "start" }}
              className="header-text font-[700]  text-trueGray900 dark:text-trueGray50"
            >
              {header || "Что говорят о нас студенты"}
            </p>
          </div>
          <div className="hidden md:flex gap-7">
            <Button
              className="w-[48px] h-[48px] rounded-[10px] dark:bg-[#334155] bg-white text-red-300"
              onClick={() => sliderRef.current.slickPrev()}
            >
              <LeftOutlined
                fill={"#0284C7"}
                svgStyle={{ display: "inline-block", verticalAlign: "middle" }}
              />
            </Button>
            <Button
              className="w-[48px] h-[48px] rounded-[10px] dark:bg-[#334155] bg-white"
              onClick={() => sliderRef.current.slickNext()}
            >
              <RightOutlined
                fill={"#0284C7"}
                svgStyle={{ display: "inline-block", verticalAlign: "middle" }}
              />
            </Button>
          </div>
        </div>
        <Slider ref={sliderRef} {...settings} className="px-2">
          {reviewers?.data?.length > 0 &&
            reviewers?.data?.map((elem, i) => (
              <div key={i}>
                <ReviewCard
                  text={elem?.description}
                  image={`${process.env.REACT_APP_ASSETS_URL}${elem.reviewerFormat}`}
                  reviewerName={elem?.fullName}
                  courseName={elem?.courseName}
                />
              </div>
            ))}
        </Slider>
      </div>
    );
  }
  return (
    <div className={" container mx-auto px-5 md:px-0"}>
      <div className="flex justify-between items-center mb-10 mt-10 ">
        <div>
          <p
            style={{ textAlign: "start" }}
            className="header-text font-[700]  text-trueGray900 dark:text-trueGray50"
          >
            {header}
          </p>
          {desc ? (
            <p className="text-trueGray600 text-[16px] font-[700] text-start  dark:text-trueGray50">
              {desc}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ExperimentalSwiper;
