import React from "react";
import { useTranslation } from "react-i18next";
import img1 from "../Components/swiperSlider/image 146.svg";
import img3 from "../Components/swiperSlider/image 148.svg";
import img4 from "../Components/swiperSlider/image 151.svg";
import img5 from "../Components/swiperSlider/image 152.svg";

const OurSucces = ({ keyword }) => {
  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      header: t("home.success.col1.head"),
      desc: t("home.success.col1.desc"),
      img: img5,
    },
    {
      id: 2,
      header: t("home.success.col2.head"),
      desc: t("home.success.col2.desc"),
      img: img4,
    },
    {
      id: 3,
      header: t("home.success.col3.head"),
      desc: t("home.success.col3.desc"),
      img: img1,
    },
    {
      id: 4,
      header: t("home.success.col4.head"),
      desc: t("home.success.col4.desc"),
      img: img3,
    },
  ];

  const selectedData = keyword === "aboutUs" ? data : data;

  return (
    <section className="font-main">
      <div className="grid grid-cols-1 sm:grid-cols-2 justify-items-center xl:grid-cols-4 gap-8 text-start">
        {selectedData?.map((item) => (
          <article
            key={item.id}
            className="bg-white dark:bg-[#161F2D] container rounded-[16px] p-6 h-[325px]"
          >
            <h3 className="text-[40px] font-[700] text-[#0284C7]">
              {item.header}
            </h3>
            <p className="mt-2 text-[18px] pr-5 dark:text-[#FAFAFA] leading-6 font-medium text-[#262626]">
              {item.desc}
            </p>
            <figure className="w-[200px] h-[200px] mx-auto">
              <img src={item.img} alt={item.header} className="w-full h-full" />
              <figcaption className="sr-only">{item.desc}</figcaption>
            </figure>
          </article>
        ))}
      </div>
    </section>
  );
};

export default OurSucces;
