import { IconButton } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Mentors from "../../../Components/Mentors/Mentors";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { useAboutUsQuery } from "../../../api/aboutUs";
import { Link, useOutletContext } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import ButtonRegister from "../../../Components/ButtonRegister";

const Teachers = ({ pageKey = "" }) => {
  const { t } = useTranslation();
  const [, lang] = useOutletContext();
  const swiperRef = useRef();
  const { data: mentors } = useAboutUsQuery({ lang });
  return (
    <div>
      <div className="pt-[100px]">
        <div className="container mx-auto overflow-hidden">
          <div className="flex justify-between items-center mb-10 ">
            <div>
              <h3 className="header-text font-bold  mb-[12px] dark:text-trueGray100 ">
                {t("courses.form.home-texts-7")}
              </h3>
            </div>
            <div className="sm:flex hidden items-center gap-4">
              <IconButton
                sx={{ bgcolor: "white" }}
                onClick={() => swiperRef.current.slidePrev()}
              >
                <ArrowBackRoundedIcon
                  className="text-[#0284C7]"
                  fontSize="medium"
                />
              </IconButton>
              <IconButton
                sx={{ bgcolor: "white" }}
                onClick={() => swiperRef.current.slideNext()}
              >
                <ArrowForwardRoundedIcon
                  className="text-[#0284C7]"
                  fontSize="medium"
                />
              </IconButton>
            </div>
          </div>
          <div className="pb-[40px] lg:w-[100%] m-auto w-[95%]">
            <Swiper
              modules={[Scrollbar, A11y, Navigation, Pagination]}
              loop
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              breakpoints={{
                240: {
                  slidesPerView: 1.2,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2.5,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 30,
                },
              }}
              slidesPerView={2}
              spaceBetween={30}
            >
              {mentors?.data?.length > 0 &&
                mentors?.data?.map((e, i) => {
                  return (
                    <SwiperSlide key={i} className="rounded-[20px]">
                      <Mentors
                        avatar={`${process.env.REACT_APP_ASSETS_URL}${
                          e?.photo ? e?.photo : "public/logo192.png"
                        }`}
                        name={e?.fullName}
                        about={e?.about}
                        courseSkills={e?.teacherSkillsDtos}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
          {pageKey !== "about" ? (
            <Link
              to={"/AboutUs"}
              className="text-center py-5 flex justify-center"
            >
              <ButtonRegister text={t("courses.form.btn2")} />
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Teachers;
