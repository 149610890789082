import React, { useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import Slider from "react-slick";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CourseSkills = ({ courseSkills, dark }) => {
  const [more, setMore] = useState(false);
  const [showId, setShowId] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: "skills-slider",
    centerMode: true,
    centerPadding: "30px",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 2.5,
          infinite: true,
        },
      },
      {
        breakpoint: 768, // phone breakpoint
        settings: {
          slidesToShow: 1.5,
          infinite: true,
        },
      },
      {
        breakpoint: 480, // small phone breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="container px-2 mx-auto mt-[60px]">
        <div>
          <h1 className="text-[#262626] dark:text-[#FAFAFA] text-[20px] mb-10 font-[700]">
            Чему вы научитесь
          </h1>
          <Slider {...settings}>
            {courseSkills?.map((course) => (
              <div key={course?.id}>
                <Box className="flex flex-col border-r items-start text-start p-4">
                  <Avatar
                    src={`${process.env.REACT_APP_ASSETS_URL}${course?.logo}`}
                    alt={"logo"}
                    className="mb-2"
                    style={{ width: 56, height: 56 }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      color: dark === "dark" ? "#262626" : "white",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                    className="font-semibold mb-2"
                  >
                    {course?.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      color: dark === "dark" ? "#262626" : "white",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                    className="text-gray-600"
                  >
                    {showId === course.id && more
                      ? course?.description
                      : course?.description !== null
                      ? course?.description.split(" ").slice(0, 11).join(" ") +
                        ". . ."
                      : ""}
                    {showId === course.id && more ? (
                      <span
                        className="cursor-pointer"
                        onClick={() => setMore(false)}
                      >
                        <ArrowDropUpIcon />
                      </span>
                    ) : (
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setMore(true);
                          setShowId(course.id);
                        }}
                      >
                        <ArrowRightIcon />
                      </span>
                    )}
                  </Typography>
                </Box>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default CourseSkills;
