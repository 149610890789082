import { Instagram, Telegram, WhatsApp } from "@mui/icons-material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import { Container, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo.svg";

function Info({ dark }) {
  const { t } = useTranslation();

  return (
    <footer className="bg-[#F8FAFC] dark:bg-[#0F172A]  font-main py-8">
      <Container>
        <Grid container spacing={4} mt={1}>
          <Grid item xs={12} sm={5} md={5} lg={5}>
            <img
              src={logo}
              alt="SoftClub"
              className="h-[64px] w-[141px] mb-4"
            />
            <Typography
            component={"h3"}
              sx={{
                fontSize: "20px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                lineHeight: "28px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
            >
              {t("footer.text2")}
            </Typography>
          </Grid>
          <Grid item  xs={0} sm={10} md={2} lg={1}></Grid>
          <Grid
            item
            xs={12}
            sm={4}
            lg={3}
            sx={{
              borderRight: {
                lg: "1px solid #E2E8F0",
                xs: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                lineHeight: "28px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
              gutterBottom
            >
              <div className="h-[1px] lg:hidden bg-[#E2E8F0] mb-3"></div>
              {t("footer.header")}
            </Typography>
            <ul className="list-none text-[#262626] dark:text-[#FAFAFAFA] p-0 text-[16px] font-[500]">
              <li
                className="cursor-pointer"
                onClick={() => {
                  window.scroll({
                    top: 5700,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h1>{t("home.header.btn-more")}</h1>
              </li>
              <li
                className="cursor-pointer"
                onClick={() => {
                  window.scroll({
                    top: 3900,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h1>{t("home.courses.header")}</h1>
              </li>
              <li className="cursor-pointer">
                <h1>{t("footer.blogs")}</h1>
              </li>
              <li className="cursor-pointer">
                <h1>{t("footer.about-us")}</h1>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className="h-[1px] lg:hidden bg-[#E2E8F0] mb-3"></div>
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                lineHeight: "28px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
              gutterBottom
            >
              Контакты
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                lineHeight: "24px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
            >
              info@softclub.tj
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                lineHeight: "24px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
            >
              +992 558 700 900
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                lineHeight: "24px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
            >
             {t("footer.address")}
            </Typography>
            <div className="flex items-center mt-4">
              <IconButton
                href="https://www.facebook.com/infosoftclub.tj/"
                className="text-blue-500"
              >
                <FacebookRoundedIcon sx={{ color: "#075985" }} />
              </IconButton>
              <IconButton
                href="https://t.me/softclubtj"
                className="text-blue-400"
              >
                <Telegram sx={{ color: "#075985" }} />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/softclub.tj/"
                className="text-pink-500"
              >
                <Instagram sx={{ color: "#075985" }} />
              </IconButton>
              <IconButton href="https://softclub.tj" className="text-red-500">
                <WhatsApp sx={{ color: "#075985" }} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Info;
