import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import m1 from "../../video/authors/m1.png";
import m2 from "../../video/authors/m2.png";
import m3 from "../../video/authors/m3.png";
import m4 from "../../video/authors/m4.png";
import m5 from "../../video/authors/m5.png";
import m6 from "../../video/authors/m6.png";
import m7 from "../../video/authors/m7.png";
import m8 from "../../video/authors/m8.png";
import m9 from "../../video/authors/m9.png";
import v1 from "../../video/v1.mp4";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import v2 from "../../video/v2.mp4";
import v3 from "../../video/v3.mp4";
import v4 from "../../video/v4.mp4";
import v5 from "../../video/v5.mp4";
import v6 from "../../video/v6.mp4";
import v7 from "../../video/v7.mp4";
import v8 from "../../video/v8.mp4";
import v9 from "../../video/v9.mp4";
import "./review.css";

const StudentsReview = () => {
  const testimonials = [
    {
      id: 1,
      name: "Илхом Саидов",
      videoSrc: v1,
      thumbnailSrc: m1,
    },
    {
      id: 2,
      name: "Шохдидор Ходжаев",
      videoSrc: v2,
      thumbnailSrc: m3,
    },
    {
      id: 3,
      name: "Шахром Шоҳимардонов",
      videoSrc: v3,
      thumbnailSrc: m4,
    },
    {
      id: 4,
      name: "Баракатулло Тимуров",
      videoSrc: v4,
      thumbnailSrc: m2,
    },
    {
      id: 5,
      name: "Сафаров Ахмад",
      videoSrc: v5,
      thumbnailSrc: m9,
    },
    {
      id: 6,
      name: "Сафия Дадачонова",
      videoSrc: v6,
      thumbnailSrc: m7,
    },
    {
      id: 7,
      name: "Мехриддин Саидов",
      videoSrc: v7,
      thumbnailSrc: m6,
    },
    {
      id: 8,
      name: "Ниёиш Бахши",
      videoSrc: v8,
      thumbnailSrc: m8,
    },
    {
      id: 9,
      name: "Зохира Фурмол",
      videoSrc: v9,
      thumbnailSrc: m5,
    },
  ];
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const sliderRef = useRef(null);
  const sectionRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      const rect = section?.getBoundingClientRect();
      if (rect.top >= window.innerHeight || rect.bottom <= 0) {
        setPlayingVideoId(null);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting && playingVideoId !== null) {
            setPlayingVideoId(null);
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(sectionRef.current);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      observer.disconnect();
    };
  }, [playingVideoId]);

  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    centerMode: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="font-main dark:text-[#FAFAFA] text-[#262626]"
      ref={sectionRef}
    >
      <header className="flex px-5 items-start justify-between container">
        <div>
          <h2 className="sm:text-[28px] dark:text-[#FAFAFA] text-[20px] font-[700] mb-4">
            {t("home.headers.head3")}
          </h2>
          <p className="sm:text-[16px] text-[14px] leading-6 font-[400] mb-8">
            {t("home.headers.head5")}
          </p>
        </div>

        <div className="sm:flex hidden items-center gap-2">
          <IconButton sx={{ bgcolor: "white" }} onClick={previous}>
            <ArrowBackRoundedIcon
              className="text-[#0284C7]"
              fontSize="medium"
            />
          </IconButton>
          <IconButton sx={{ bgcolor: "white" }} onClick={next}>
            <ArrowForwardRoundedIcon
              className="text-[#0284C7]"
              fontSize="medium"
            />
          </IconButton>
        </div>
      </header>

      <Slider ref={sliderRef} {...settings}>
        {testimonials.map(({ id, name, videoSrc, thumbnailSrc }) => (
          <div key={id} className="p-2">
            <span
              className={`relative ${
                playingVideoId === id && playingVideoId ? "invisible" : "visible"
              } bottom-[-70px] right-[-10px] `}
            >
              <PlayArrowIcon
                className="drop-shadow-lg"
                sx={{
                  color: "whitesmoke",
                  fontSize:'50px',
                  filter: "drop-shadow(0px 10px 10px  rgb(0 0 0 / 1.04))",
                }}
                fontSize="large"
              />
            </span>
            <div className="rounded-2xl h-[320px]">
              <ReactPlayer
                url={videoSrc}
                playing={playingVideoId === id}
                light={thumbnailSrc}
                width="100%"
                height="100%"
                className="player"
                onClick={() => {
                  setPlayingVideoId((prev) => (prev === id ? null : id));
                }}
              />
              <div className="p-4">
                <p className="text-[16px] font-medium text-[#0284C7]">
                  Softclub
                </p>
                <p className="text-[20px] leading-6 dark:text-[#FAFAFA] font-[600] text-gray-900">
                  {name}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default StudentsReview;
